import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
			graphql`
				query SITE_METADATA_QUERY {
					site {
						siteMetadata {
							title
							description
							mainLogo
							secondaryLogo
							phone
							mobile
							fax
							street
							suburb
							city
							stateLong
							stateShort
							postcode
							facebook
							insta
							linkedin
							twitter
						}
					}
				}
			`
		);
  return site.siteMetadata
}

export default useSiteMetadata
